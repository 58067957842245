import { Popover } from 'bootstrap'

(function(attrs) {
  if (attrs.length == 0) return;

  const popoverList = [...attrs].map(attr => {
    attr.setAttribute('role', 'button')
    attr.setAttribute('tabindex', '0')
    return new Popover(attr, {
      offset: '[0, 20]',
      title: el => el.textContent,
      trigger: 'focus'
    })
  })

})(document.querySelectorAll('ul.attr-list a'))
