(function(container) {
  if (!container) return;

  /* DOM Elements */

  const el = {
    map: container.querySelector('.map-svg'),
    title: container.querySelector('.trail-title'),
    view: container.querySelector('.trail-view'),
    slide: container.querySelector('.trail-map-slide'),
    groups: [...container.querySelectorAll('.map-svg-group')], // ?
    points: [...container.querySelectorAll('.map-svg-point')],
    posts: [...container.querySelectorAll('.trail-post')],
    nav: container.querySelector('.trail-nav')
  }

  /* Data Object */

  const data = {
    title: el.title.textContent,
    groups: [],
    points: [],
    currentGroup: null,
    currentPoint: null,
    direction: null,
    locked: false
  }

  /* Group Prototype */

  function Group(el, index) {
    this.el = el
    this.region = this.el.children[0]
    this.track = this.el.children[2]
    this.points = []
    this.index = index
    this.id = el.id
    this.prefix = this.el.dataset.prefix
    // 
    this.region.addEventListener('click', () => onClickRegion(this))
    // implement hover event
    this.el.addEventListener('mouseenter', () => this.hover() )
    this.el.addEventListener('mouseleave', () => this.hover() )
  }

  Group.prototype.current = function() {
    /* a może wykorzystać classList replace (if false) add aclass */
    if (data.currentGroup) {
      el.slide.classList.remove(data.currentGroup.id)
    }
    el.slide.classList.add(this.id)
    data.currentGroup = this
  }

  Group.prototype.hover = function() {
    this.el.classList.toggle('hover')
  }

  /* Point Prototype */

  function Point(el, name, group, post) {
    this.el = el
    this.text = el.nextElementSibling
    this.name = name
    this.group = group
    this.post = post
    // push point to self group
    this.group.points.push(this)
    // add a click event
    this.el.addEventListener('click', () => onClickPoint(this))
    this.text.addEventListener('click', () => onClickPoint(this))
    // implement hover event
    this.el.addEventListener('mouseenter', () => this.hover() )
    this.el.addEventListener('mouseleave', () => this.hover() )
    this.text.addEventListener('mouseenter', () => this.hover() )
    this.text.addEventListener('mouseleave', () => this.hover() )
  }

  Point.prototype.index = function() {
    return data.points.indexOf(this)
  }
  
  Point.prototype.hover = function() {
    this.el.classList.toggle('hover')
  }

  Point.prototype.toggle = function(animate) {
    this.el.classList.toggle('current')
    this.post.classList.toggle('current')
  }
  
  Point.prototype.fadeOut = function(direction) {
    onceAnimation(this.post, direction == 'right' ? 'fadeOutLeft' : 'fadeOutRight')
    this.toggle()
  }

  Point.prototype.fadeIn = function(direction) {
    onceAnimation(this.post, direction == 'right' ? 'fadeInRight' : 'fadeInLeft')
    this.toggle()
  }

  /* Component Methods */

  const switchPoint = async (point, direction, animate = false) => {
    if (!(point instanceof Point || direction)) return

    // update point and post

    if (data.currentPoint)
      animate ? data.currentPoint.fadeOut(direction) : data.currentPoint.toggle()
    animate ? point.fadeIn(direction) : point.toggle()

    // update data object

    data.currentPoint = point
    data.currentGroup = point.group
    data.direction = direction
    data.locked = false
  }

  /* On Click Point Controller */

  const onClickPoint = async (point, directionParam) => {
    if (data.locked) return
    
    data.locked = true

    if (!data.currentGroup) {

      // console.log('onClickPoint: 1. first group on view "one"')

      await onceAnimation(el.view, 'zoomOutView')
      el.view.dataset.view = 'two'
      el.slide.classList.add(`slide-${point.group.id}`,'slide-in-right')
      setPrefixTitle(point.group.prefix)
      switchPoint(point, 'right')
      onceAnimation(el.view, 'zoomInView')

    } else if (point.group == data.currentGroup) {

      const direction = directionParam || (point.index() > data.currentPoint.index() ? 'right' : 'left')

      if (el.view.dataset.view == 'one') {

        // console.log('onClickPoint: 2. same group on view "one"')

        await onceAnimation(el.view, 'zoomOutView')
        el.view.dataset.view = 'two'
        el.slide.classList.replace(`slide-in-${data.direction}`,`slide-in-${direction}`)
        setPrefixTitle(point.group.prefix)
        switchPoint(point, direction)
        onceAnimation(el.view, 'zoomInView')

      } else {

        // console.log('onClickPoint: 3. same group on view "two"')

        el.slide.classList.replace(`slide-in-${data.direction}`,`slide-in-${direction}`)
        switchPoint(point, direction, true)
      }
      
    } else if (el.view.dataset.view == 'one') {

      // console.log('onClickPoint: 4. other group on view "one"')

      const direction = getDirectionByGroup(point.group)

      await onceAnimation(el.view, 'zoomOutView')
      el.view.dataset.view = 'two'
      el.slide.classList.replace(`slide-${data.currentGroup.id}`, `slide-${point.group.id}`)
      el.slide.classList.replace(`slide-in-${data.direction}`,`slide-in-${direction}`)
      setPrefixTitle(point.group.prefix)
      switchPoint(point, direction)
      onceAnimation(el.view, 'zoomInView')

    } else {

      // console.log('onClickPoint: 5. other group on view "two" from "Prev or Next"')

      const direction = directionParam

      el.slide.classList.replace(`slide-${data.currentGroup.id}`,`slide-${point.group.id}`)
      el.slide.classList.replace(`slide-in-${data.direction}`,`slide-in-${direction}`)
      el.slide.classList.add('slide-smooth')
      setPrefixTitle(point.group.prefix)
      switchPoint(point, direction, true)
    }
  }

  /* On Click Region Controller */

  const onClickRegion = async (group) => {
    if (data.locked) return
    
    data.locked = true

    if (!data.currentGroup) {

      // console.log('onClickRegion: 1. first group on view "one"')

      await onceAnimation(el.view, 'zoomOutView')
      el.view.dataset.view = 'two'
      el.slide.classList.add(`slide-${group.id}`,'slide-in-right')
      setPrefixTitle(group.prefix)
      switchPoint(group.points[0], 'right')
      onceAnimation(el.view, 'zoomInView')

    } else if (group != data.currentGroup) {

      const direction = getDirectionByGroup(group)
      const point = direction == 'right' ? group.points[0] : group.points[group.points.length -1]

      if (el.view.dataset.view == 'one') {

        // console.log('onClickRegion: 2. other group on view "one"')

        await onceAnimation(el.view, 'zoomOutView')
        el.view.dataset.view = 'two'
        el.slide.classList.replace(`slide-${data.currentGroup.id}`, `slide-${group.id}`)
        el.slide.classList.replace(`slide-in-${data.direction}`,`slide-in-${direction}`)
        setPrefixTitle(group.prefix)
        switchPoint(point, direction)
        onceAnimation(el.view, 'zoomInView')

      } else {

        // console.log('onClickRegion: 3. other group on view "two"')

        el.slide.classList.replace(`slide-${data.currentGroup.id}`,`slide-${group.id}`)
        el.slide.classList.replace(`slide-in-${data.direction}`,`slide-in-${direction}`)
        el.slide.classList.add('slide-smooth')
        setPrefixTitle(group.prefix)
        switchPoint(point, direction, true)
      }

    } else if (el.view.dataset.view == 'one') {

      // console.log('onClickRegion: 4. same group on view "one"')

      await onceAnimation(el.view, 'zoomOutView')
      el.view.dataset.view = 'two'
      setPrefixTitle(group.prefix)
      data.locked = false
      await onceAnimation(el.view, 'zoomInView')
    }
  }

  /* On Click Prev Controller */

  const onClickPrev = () => {
    if (data.locked) return
    const index = data.currentPoint.index()
    const point = index > 0 ? data.points[index - 1] : data.points[data.points.length - 1]
    onClickPoint(point, 'left')
  }

  /* On Click Next Controller */

  const onClickNext = () => {
    if (data.locked) return
    const index = data.currentPoint.index()
    const point = index < data.points.length - 1 ? data.points[index + 1] : data.points[0]
    onClickPoint(point, 'right')
  }

  /* On Click Back Controller */

  const onClickBack = async () => {
    if (data.locked) return
    
    data.locked = true

    await onceAnimation(el.view, 'zoomOutView') // test: zoomOutView2
    el.view.dataset.view = 'one'
    el.slide.classList.remove('slide-smooth')
    setPrefixTitle()
    data.locked = false
    onceAnimation(el.view, 'zoomInView') // test: zoomOutView1
  }

  /* Helper Functions */

  const createGroups = () => {
    el.groups.forEach((group, index) => {
      data.groups.push(new Group(group, index))
    })
  }

  const createPoints = () => {
    el.posts.forEach(post => {
      const name = post.dataset.name.toLowerCase()
      const point = el.points.find(point => point.dataset.name.toLowerCase() == name)
      if (point) {
        const group = data.groups.find(group => group.id == point.parentElement.id)
        data.points.push(new Point(point, name, group, post))
        point.classList.add('active')
      }
    })
  }

  const getDirectionByGroup = group => {
    const index = data.currentGroup.index
    const nextIndex = index < data.groups.length - 1 ? index + 1 : 0
    return group.index == nextIndex ? 'right' : 'left'
  }

  const setPrefixTitle = (prefix) => {
    const title = prefix ? `${data.title} <span>${prefix}</span>` : data.title
    el.title.innerHTML = title
  }

  const onceAnimation = (el, classname) => {
    return new Promise(resolve => {
      const callback = () => {
        el.removeEventListener('animationend', callback);
        el.classList.remove(classname)
        resolve()
      }
      el.addEventListener('animationend', callback)
      el.classList.add(classname)
    });
  }

  /* Init Function */

  (function() {
    // set view
    if (!el.view.dataset.view) {
      el.view.dataset.view = 'one'
    }
    // create groups
    createGroups()
    // create points
    createPoints()
    // debug
    // console.log(data.groups)
    // console.log(data.points)
    // add a click event on the nav
    el.nav.addEventListener('click', evt => {
      switch (evt.target.className) {
        case 'trail-nav-prev': onClickPrev(evt); break;
        case 'trail-nav-next': onClickNext(evt); break;
        case 'trail-nav-back': onClickBack(evt); break;
      }
    })
  })()

})(document.getElementById('trail'));

/* 

  1. punkty ułożone są według kolejności artykułów!

*/