import { Carousel } from 'bootstrap'

(function(banner) {
  if (!banner) return;

  const active = banner.querySelector('.active')

  const setAuthor = author => {
    banner.dataset.author = author
  }

  setAuthor(active.dataset.author)

  const carousel = new Carousel(banner, {
    interval: 4000,
    pause: false
  }).cycle()
  
  banner.addEventListener('slid.bs.carousel', evt => {
    setAuthor(evt.relatedTarget.dataset.author)
  })

})(document.querySelector('.banner'))
